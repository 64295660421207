.assignment{
  margin: 0;
  margin-top: -1em;
	position: relative;
	z-index: 888;
	
  
  &__body {
		padding-top: 1.5em;
		padding: 1em;

		.assignmentCardRow {
			.assignementCard {
				margin: 1em;
				padding: 1em;


			}
		}

		.formContainer {
			padding: 1em;
		}
  }
  
}


// ####### assignmentData START
.assignmentData {
	position: relative;
	height: 100%;

	&__id {
		display: flex;
		justify-content: flex-end;
		color: gray;
		font-weight: bold;
	}

	&__dataContainer {
		display: flex;
		margin-top: 0.5em;
		position: relative;
		.header{
			position: absolute;
			top: -2em;
			right: 0;
		}

		.icon {
			width: 20%;
			height: 45px;
			border-left: 4px solid $blue;
			@include flex-center;
			span {
				background: $blue;
				width: 40px;
				height: 40px;
				color: #fff;
				padding: 1em;
				@include flex-center;
				border-radius: 8px;
			}
		}
		.studentDetails {
			width: 80%;
			h4 {
				margin: 0;
				font-size: 1.3em;
			}
			p {
				@include flex-center;
				justify-content: flex-start;
				svg {
					width: 0.7em;
					height: 0.7em;
				}
			}
		}
	}

	&__details {
		flex-direction: column;
		width: 100%;
		margin: 0;
		margin-bottom: 1em;

		h5 {
			margin: 0;
			font-weight: bold;
			margin: 0.5em 0;
      font-size: 1.1em;
      
      span{
        font-weight: normal;
        margin-left: 0.6em;
      }
		}

	}

	&__btnContainer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		button {
			background: #fff;
			text-transform: capitalize;
			border: 1px solid rgba(105, 37, 151, 0.516);
		}
	}
}
// ####### assignmentData END

// ####### createAssignmentForm START
.createAssignmentForm {
	position: relative;
	&__header {
		width: 100%;
		@include flex-center;

		h3 {
			text-align: center;
			font-size: 1.5em;
			padding: 0.5em 0;
			margin-bottom: 0;
		}
	}

	&__body {
		padding: 1em;
		display: flex;
		flex-direction: column;
		align-items: center;

		.form {
			display: flex;
			flex-direction: column;
			align-items: center;
			min-width: 300px;
			max-width: 100%;
			position: relative;

			.input {
				min-width: 300px;
				max-width: 100%;
				height: 40px;
			}
			.upload {
				min-width: 300px;
				max-width: 100%;
        .MuiDropzoneArea-root{
          width: 100%;
          min-width: 300px;
          max-width: 100%;
          position: relative;

          .MuiDropzoneArea-textContainer{

            p{
              font-size: 1em !important;
            }

          }
        }
			}

			.createAssignmentBtn {
				background: $blue;
				color: #fff;
			}
		}
	}
}
// ####### createAssignmentForm START



// ####### assignmentView START
.assignmentView {
	position: relative;
	padding: 0;
	margin: 0;
	height: calc(100vh - 80px);

	&__backBtn {
		background: #fff !important;
		color: rgb(37, 36, 36) !important;
		box-shadow: $shadow;
		cursor: pointer;
		width: 35px !important;
		height: 35px !important;
		position: absolute !important;
		top: 0 !important;
		left: 0 !important;
		z-index: 10;
		&:active {
			background: rgb(234, 234, 233) !important;
		}
	}

	&__body {
		padding: 0;
		margin: 0;
		position: relative;
		display: flex;
		height: 100%;

		.col {
			height: 100%;
		}

		.contentContainer {
			padding: 1em;
			height: 100%;
			overflow: auto;
			@include purple-scrollbar;
		}

		.assignmentCard {
			margin: 1em 0;
			padding: 3em 1em;
			cursor: pointer;

			&:active {
				background: rgb(240, 238, 238);
			}
		}

		#activeCard {
			border-bottom: 4px solid $blue;
		}

		.rightCard {
			height: 100%;

			.assignmentDetailCard {
				height: 100%;
				padding-bottom: 0;
				.top {
					height: 30%;
					overflow: auto;
					@include purple-scrollbar;
					position: relative;
					font-size: 11px;
					padding-top: 0;
					margin: 0;
					.topBtn {
						background: #fff;
						text-transform: capitalize;
					}
					.joinBtn {
						background: $blue;
						color: #eee;
					}

					.assignmentData__id {
						position: sticky;
						top: 0;
						left: 0;
						width: 100%;
						padding: 1px 1em;
						font-size: 9px;
					}

					.assignmentData__dataContainer {
						width: 100%;
						max-width: 400px;
					}
					h5 {
						margin: 0;
						font-size: 1.1em;
						padding-bottom: 8px;
					}

				}

				.bottom {
					height: 70%;
					overflow: auto;
					overflow-x: hidden;
					@include purple-scrollbar;

					.studentList {
						width: 100%;
						height: 100%;
						.table {
							position: relative;
							.tableTop {
								padding: 0 1em 1em 1em;
								@include flex-center;
								justify-content: space-between;

								h5 {
									margin: 0;
									font-size: 1.5em;
									margin: 0.5em 0;
								}

								button {
									background: #fff;
									margin: 0;
									height: 20px;
									text-transform: capitalize;
									margin-right: 1em;
								}
							}

							.col {
								text-align: left;
							}
							.header {
								width: 100%;
								padding-bottom: 0.6em;
								border-bottom: 1px solid gray;
								background: #fff;
								font-size: 10px;
								position: sticky;
								top: 0;
								left: 0;
								z-index: 10;
								.col {
									@include flex-center;
									justify-content: flex-start;
								}
								p {
									font-weight: bold;
									font-size: 1.2em;
								}

								.totalCheck {
									padding: 0;
									margin: 0;
									margin-left: 0.3em;
								}

								.headerRow {
									padding: 0 1em;
									padding-top: 0;
									padding-bottom: 0;

									.col {
										padding-left: 0;
									}
								}

								.searchInput {
									padding: 0 1em;
									border-radius: 20px;
									@include flex-center;
									input {
										font-size: 0.8em;
										border: none !important;
										outline: none;
									}
								}
							}

							.body {
								width: 100%;

								.row {
									width: 100%;
									padding: 0.5em 0;
									border-bottom: 1px solid lightgray;
									font-size: 0.5px;
									@include flex-center;
								}
								.col {
									@include flex-center;
									justify-content: flex-start;
									align-items: center;
								}
								.name {
									padding-left: 1em;
								}

								.avatar{
									width: 30px;
									height: 30px;
								}
								.viewBtn {
									text-transform: capitalize;
									padding: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
// ####### assignmentView END