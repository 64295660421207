.detailsForm{
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(146, 40, 228, 0.414);
  padding: 2em;
  padding-top: 6em;

  &__mainRow{
    height: 100%;
    width: 100%;

    .left{
      height: 100%;
      width: 90%;
      max-width: 600px;
      margin:  auto;
      @include flex-center;

      img{
        height: 100%;
        width: 100%;
      }
    }

    .right{
      @include flex-center;
      height: 100%;
      width: 100%;
    }
  }

  &__card{
    height: 100%;
    width: 100%;
    overflow: auto !important;
    border-radius: 15px;
    padding: 1em;
    width: 100%;
    max-width: 500px;
    @include purple-scrollbar;
    position: relative;

    .header{
      @include flex-center;
      justify-content: space-between;
      position: sticky;
      width: 100%;
      top: 0;
      left: 0;
      img{
        width: 100px;
        height: auto;
      }

      .stepIndicator{
        @include flex-center;
        justify-content: space-between;
        width: 120px;
        position: relative;
        span{
          background: rgb(235, 211, 251);
          width: 18px;
          height: 18px;
          border-radius: 50%;
          font-size: 9px;
          color: gray;
          cursor: pointer;
          @include flex-center;
        }
        .active{
          background: rgb(206, 135, 253);
          width: 22px;
          height: 22px;
          border: 2px solid $blue;
          font-size: 13px;
          color: $blue;
        }

        &::before{
          content: "";
          width: 100%;
          height: 2px;
          background: $blue;
          position: absolute;
          z-index: -1;
        }
      }
    }

    .body{
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      .avatar{
        width: 90px;
        height: 90px;
        color: $blue;
        background: rgb(213, 152, 246);
      }

      form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        position: relative;
        padding: 0 1em;

        .text-left,
        small{
          text-align: left;
          width: 100%;
          margin-bottom: -1em;
        }

        small{
          color: gray;
        }

        .input{
          width: 100%;
          height: 40px;
          margin: 0 auto;
        }

        .groupOfTwo{
          width: 100%;
          display: flex !important;
          justify-content: space-between !important;

          .MuiInputBase-root{
            width: 100% !important;
          }
        }

        .radiogroup{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          .MuiFormGroup-root{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;         
            padding: 0 1em;
            width: 100% !important;
          }
        }
        
      }

      button{
        &:disabled {
          background: #dddddd;
          color: gray;
        }
      }

      .submitLoading{
        position: relative;
        bottom: 2em;
      }
     
    }
  }
}