.toolbar_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;

    img {
      width: 50px;
      height: auto;
      z-index: 999;
      padding-top: 28px;
    }
  }
}

.invite-btn {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0 2em;
  i {
    font-size: 1.4em;
    padding: 0 4px;
  }
}

.tool_container {
  background: $gray;
  border: 1px solid $gray_d1;
  border-radius: 5px;
  width: fit-content;
  padding: 0.5em 1em;
  position: relative;
  z-index: 999;
  cursor: pointer;
}

.tool {
  position: relative;
  z-index: 999;
  cursor: pointer;
}

.toolbar_left {
  width: 80px;
  height: 100vh;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.toolbar_right {
  width: 80px;
  height: 100vh;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .board-tools {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #fff;
    padding: 0;
  }
}

.board-tools {
  padding: 1em 0px;
  width: 55px;
  text-align: center;
  position: relative;

  .top-right-icon {
    position: absolute;
    right: -30px;
    top: -28px;
    z-index: 999;
    img {
      width: 60px;
      height: auto;
    }
  }

  .board-tool {
    padding: 8px 4px;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 4px 0;
    background: transparent;
    border-radius: 4px;

    span > img {
      width: 3.5em;
    }

    &:hover {
      background: #dbd6d6;
    }

    &:active {
      background: #c3c3c3;
      outline: none;
    }
  }
}

.scale-tool {
  padding: 0;
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  button {
    margin: 0;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    img {
      width: 100px;
      height: auto;
      outline: none;
      cursor: pointer;
    }
  }

  .scale-value {
    position: absolute;
    bottom: -20px;
    background: #fff;
    border-radius: 50%;
    font-size: 13px;
    padding: 2px;
    color: $scoar_purple;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

.pages {
  background: #fff;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid $gray;
  position: absolute;
  bottom: 2em;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  button {
    background: transparent;
    border: none;
    color: gray;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    margin: 0 2px;
    &:hover {
      background: #dbd6d6;
    }

    &:active {
      background: #c3c3c3;
      outline: none;
    }
  }
}

.tool-popover .MuiPopover-paper {
  // override default style of the tool popover
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: $gray;
  border: 1px solid $gray_d1;
  margin-left: 10px;
}

.tool-popover-container {
  padding: 0.5rem;

  .color-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    padding-bottom: 8px;
    border-bottom: 1px solid lightgray;

    span {
      width: 30px;
      height: 30px;
      margin: 0 4px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    span[id="red"] {
      background: #fc0303;
    }

    span[id="green"] {
      background: #06bf00;
    }

    span[id="black"] {
      background: black;
    }

    span[id="blue"] {
      background: #0059ff;
    }

    span[id="yellow"] {
      background: #fcba03;
    }
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 0 4px;
    cursor: pointer;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
  }

  .color-picker-icon {
    background: red;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    position: relative;
    cursor: pointer;

    img {
      position: absolute;
      width: 60px;
      height: auto;
      top: -140%;
      left: -10px;
      padding: 0;
      margin: 0;
    }
  }

  .tool-item-container {
    display: flex;
    padding-bottom: 4px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 4px;
    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 4px;

      &:active {
        background: rgba(0, 0, 0, 0.095);
        outline: none;
      }
    }
  }

  .fixed-line-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .brush-size-slider-container {
    p {
      font-size: 13px;
      font-weight: bold;
    }
  }

  // end of .tool-popover-container
}

.whiteboard-top-right {
  display: flex;
  margin-right: 1em;
  .popover-btn-container {
    button {
      border: none;
      background: transparent;
      outline: none;
      cursor: pointer;
      border-radius: 4px;
      &:active {
        background: rgba(0, 0, 0, 0.067);
      }
    }
  }
}

.whiteboard-top-popover {
  .MuiPaper-root {
    padding: 0;
    margin-top: 30px;
  }
  .popover-inner {
    display: flex;
    flex-direction: column;
  }
}

#circle-svg-img {
  img{
    width: 18px;
  }
}