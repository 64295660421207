.whiteboard {
  background: #fff;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;

  .canvas_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// Fix- video iframe overlap
iframe{
  z-index: 0;
}