.dashboard {
	position: relative;
	padding: 0em;
	z-index: 888;

	&__nav{
		@include flex-center;
		flex-direction: column;
		align-items: flex-start;
		a{
			color: unset;
			text-decoration: none;
			padding: 1em;
			width: 100%;
			@include flex-center;
			justify-content: flex-start;
			color: rgb(71, 71, 71);
			&:hover{
				background: rgba(0, 0, 0, 0.115);
			}
			span{
				padding: 0 1em;
			}
		}
		.active {
			background: $blue;
			color: #fff;
			&:hover{
				background: $blue;
				opacity: 0.9;
			}
		}
	}

	.card {
		height: 100%;
		max-height: 250px;

		.cardContent {
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			@include purple-scrollbar;
		}
	}

	&__topRow {
		.welcome {
			@include flex-center;
			flex-direction: column;
			height: 100%;
			h1,
			h2 {
				margin: 0;
				text-align: center;
			}
		}

		.createClassroom {
			@include flex-center;
			flex-direction: column;
			height: 100%;

			button {
				@include flex-center;
				flex-direction: column;
				@include gradient-bg;
				width: 100%;
				height: 100%;
				color: #fff;
				svg {
					width: 50px;
					height: 50px;
				}
				h2 {
					margin: 0;
					text-transform: capitalize;
				}
			}
		}

		.paymentCard {
			position: relative;
			height: 100%;
			.MuiCardHeader-root {
				padding: 0.5em 1em;
			}

			.header {
				@include flex-center;
				flex-wrap: wrap;
				justify-content: space-between;

				.moneyCard {
					margin: 2px;
					padding: 0;
					button {
						padding: 8px;
						margin: 0;
						border-radius: 8px;
					}
					.active {
						border: 2px solid orange;
						box-shadow: $shadow;
					}
				}
			}
		}
	}

	&__bottomRow {
		.classroomCard {
			position: relative;
			height: 100%;
			max-height: 550px;
			.MuiCardHeader-root {
				padding: 1em;
			}

			.classroomItem {
				@include flex-center;
				// border-left: 4px solid $blue;
				margin: 0.5em 0;
				&__left {
					@include flex-center;
					padding: 0;
					.icon {
						background: $blue;
						border-radius: 8px;
						box-shadow: $shadow;
						@include flex-center;
						height: 40px;
						width: 40px;
						color: #fff;
					}
				}

				// &__center{}

				&__right {
					@include flex-center;
					button {
						margin: 0 0.5em;
						text-transform: capitalize;
						font-size: 13px;
					}
				}

				&__weekView{
					flex-direction: column;
					padding-bottom: 1em;
					border-bottom: 2px solid lightgray;
					width: 100%;
					margin: 0;
					margin-bottom: 1em;

					h5{
						margin: 0;
						font-weight: bold;
						margin: 0.5em 0;
						font-size: 1.1em;
					}
					.week{
					@include flex-center;
					justify-content: space-between;

					.day{
						@include flex-center;
						flex-direction: column;
						font-size: 9px;
						margin: 0 2px;
						.active{
							color: #fff;
							background: lightseagreen;
						}
					}

					}
				}
			}
		}

		.scheduleCard {
			position: relative;
			height: 100%;
			max-height: 550px;
			.MuiCardHeader-root {
				padding: 0.5em 1em;
			}

			.scheduleItem {
				@include flex-center;
				border-left: 4px solid $blue;
				&__left {
					@include flex-center;
					padding: 0;
					.icon {
						background: $blue;
						border-radius: 8px;
						box-shadow: $shadow;
						@include flex-center;
						height: 40px;
						width: 40px;
						color: #fff;
					}
				}

				&__bottom {
					@include flex-center;
					justify-content: flex-end;
					padding: 0.5em 0;
					border-bottom: 1px solid lightgray;
					margin-bottom: 1em;
					button {
						margin: 0 0.5em;
						text-transform: capitalize;
					}
				}
			}
		}

		.assignmentCard {
			position: relative;
			height: 100%;
			max-height: 550px;

			.assignmentItem {
				padding: 1em 0;
				@include flex-center;
				border-bottom: 1px solid lightgray;

				&__left {
					.icon {
						background: $blue;
						border-radius: 8px;
						box-shadow: $shadow;
						@include flex-center;
						height: 40px;
						width: 40px;
						color: #fff;
					}
				}

				&__center {
					p {
						position: relative;
						span {
							position: absolute;
							right: 0;
							font-weight: normal;
						}
					}
				}

				&__right {
					@include flex-center;
					button {
						text-transform: capitalize;
					}
				}
			}
		}
	}
}

.paymentPeople {
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.07);
	padding: 0.5em;
	border-radius: 8px;
	margin: 0.5em auto;
	font-weight: bold;
	&__left {
		@include flex-center;
		font-size: 0.8em;
		color: gray;

		.dateData {
			padding: 0;
			padding-right: 4px;
		}
	}

	&__center {
		@include flex-center;
		flex-direction: column;
		align-items: flex-start;
		overflow: hidden;
		font-size: 0.8em;
		color: gray;
		padding: 0 0.5em;
		padding-left: 1em;
		.orange {
			color: rgb(255, 81, 0);
		}
	}

	&__right {
		@include flex-center;

		button {
			font-size: 0.8em;
			text-transform: capitalize;
			padding: 2px 4px;
		}
	}
}
