@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

* {
  box-sizing: border-box; }

p {
  margin: 0; }

.display-none {
  display: none; }

.opacity0 {
  opacity: 0; }

.btn-transparent {
  padding: 0.7em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  font-size: 0.8em;
  background: transparent;
  outline: none; }
  .btn-transparent:active {
    background-color: #00000010; }

.btn {
  padding: 0.7em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  font-size: 0.8em; }
  .btn:hover {
    background-color: #b033ea;
    box-shadow: 0px 15px 20px #b721fd36; }

button:disabled {
  background: #dddddd;
  color: gray; }

.custom-icon {
  position: relative;
  text-align: center;
  width: 10px;
  height: 10px;
  padding: 1em;
  margin: 0; }
  .custom-icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.8em;
    height: auto; }

.btn-purple {
  background: #8657f3;
  color: #f1eeee; }

.center-text {
  width: 100%;
  text-align: center; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-bold {
  font-weight: bold; }

.text-sm-1 {
  font-size: 0.9em; }

.text-sm-2 {
  font-size: 0.8em; }

.text-sm-3 {
  font-size: 0.7em; }

.text-sm-4 {
  font-size: 0.6em; }

.text-sm-5 {
  font-size: 0.5em; }

.text-lg-1 {
  font-size: 1.1em; }

.text-lg-2 {
  font-size: 1.2em; }

.text-lg-3 {
  font-size: 1.3em; }

.text-lg-4 {
  font-size: 1.4em; }

.text-lg-5 {
  font-size: 1.5em; }

.small {
  font-size: 13px; }

.flex-grow {
  flex-grow: 1; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-grow-2 {
  flex-grow: 2; }

.m-0 {
  margin: 0; }

.loading-icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.svg-container {
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none; }
  .svg-container img {
    position: absolute;
    width: 160%;
    height: auto;
    overflow: hidden;
    outline: none;
    object-fit: cover; }

.toolbar_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .toolbar_top .logo {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999; }
    .toolbar_top .logo img {
      width: 50px;
      height: auto;
      z-index: 999;
      padding-top: 28px; }

.invite-btn {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0 2em; }
  .invite-btn i {
    font-size: 1.4em;
    padding: 0 4px; }

.tool_container {
  background: #e6e6e6;
  border: 1px solid #bababa;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5em 1em;
  position: relative;
  z-index: 999;
  cursor: pointer; }

.tool {
  position: relative;
  z-index: 999;
  cursor: pointer; }

.toolbar_left {
  width: 80px;
  height: 100vh;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }

.toolbar_right {
  width: 80px;
  height: 100vh;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .toolbar_right .board-tools {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #fff;
    padding: 0; }

.board-tools {
  padding: 1em 0px;
  width: 55px;
  text-align: center;
  position: relative; }
  .board-tools .top-right-icon {
    position: absolute;
    right: -30px;
    top: -28px;
    z-index: 999; }
    .board-tools .top-right-icon img {
      width: 60px;
      height: auto; }
  .board-tools .board-tool {
    padding: 8px 4px;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 4px 0;
    background: transparent;
    border-radius: 4px; }
    .board-tools .board-tool span > img {
      width: 3.5em; }
    .board-tools .board-tool:hover {
      background: #dbd6d6; }
    .board-tools .board-tool:active {
      background: #c3c3c3;
      outline: none; }

.scale-tool {
  padding: 0;
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer; }
  .scale-tool button {
    margin: 0;
    background: transparent;
    border: none;
    padding: 0;
    outline: none; }
    .scale-tool button img {
      width: 100px;
      height: auto;
      outline: none;
      cursor: pointer; }
  .scale-tool .scale-value {
    position: absolute;
    bottom: -20px;
    background: #fff;
    border-radius: 50%;
    font-size: 13px;
    padding: 2px;
    color: #8657f3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.pages {
  background: #fff;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  position: absolute;
  bottom: 2em;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .pages button {
    background: transparent;
    border: none;
    color: gray;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    margin: 0 2px; }
    .pages button:hover {
      background: #dbd6d6; }
    .pages button:active {
      background: #c3c3c3;
      outline: none; }

.tool-popover .MuiPopover-paper {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #e6e6e6;
  border: 1px solid #bababa;
  margin-left: 10px; }

.tool-popover-container {
  padding: 0.5rem; }
  .tool-popover-container .color-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    padding-bottom: 8px;
    border-bottom: 1px solid lightgray; }
    .tool-popover-container .color-container span {
      width: 30px;
      height: 30px;
      margin: 0 4px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .tool-popover-container .color-container span[id="red"] {
      background: #fc0303; }
    .tool-popover-container .color-container span[id="green"] {
      background: #06bf00; }
    .tool-popover-container .color-container span[id="black"] {
      background: black; }
    .tool-popover-container .color-container span[id="blue"] {
      background: #0059ff; }
    .tool-popover-container .color-container span[id="yellow"] {
      background: #fcba03; }
  .tool-popover-container input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 0 4px;
    cursor: pointer; }
  .tool-popover-container input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0; }
  .tool-popover-container input[type="color"]::-webkit-color-swatch {
    border: none; }
  .tool-popover-container .color-picker-icon {
    background: red;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    position: relative;
    cursor: pointer; }
    .tool-popover-container .color-picker-icon img {
      position: absolute;
      width: 60px;
      height: auto;
      top: -140%;
      left: -10px;
      padding: 0;
      margin: 0; }
  .tool-popover-container .tool-item-container {
    display: flex;
    padding-bottom: 4px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 4px; }
    .tool-popover-container .tool-item-container button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 4px; }
      .tool-popover-container .tool-item-container button:active {
        background: rgba(0, 0, 0, 0.095);
        outline: none; }
  .tool-popover-container .fixed-line-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .tool-popover-container .fixed-line-toggle-container p {
      font-size: 13px;
      font-weight: bold; }
  .tool-popover-container .brush-size-slider-container p {
    font-size: 13px;
    font-weight: bold; }

.whiteboard-top-right {
  display: flex;
  margin-right: 1em; }
  .whiteboard-top-right .popover-btn-container button {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    border-radius: 4px; }
    .whiteboard-top-right .popover-btn-container button:active {
      background: rgba(0, 0, 0, 0.067); }

.whiteboard-top-popover .MuiPaper-root {
  padding: 0;
  margin-top: 30px; }

.whiteboard-top-popover .popover-inner {
  display: flex;
  flex-direction: column; }

#circle-svg-img img {
  width: 18px; }

.whiteboard {
  background: #fff;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; }
  .whiteboard .canvas_container {
    display: flex;
    justify-content: center;
    align-items: center; }

iframe {
  z-index: 0; }

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .login__header {
    color: #8657f3;
    margin-bottom: 0; }
  .login__subheader {
    color: gray;
    font-weight: normal; }
  .login__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    position: relative;
    width: 100%; }
    .login__form div {
      border-radius: 25px;
      border-color: #8657f3;
      outline-color: #8657f3; }
    .login__form #input,
    .login__form .react-tel-input,
    .login__form .form-control {
      border-radius: 25px !important;
      width: 100%; }
    .login__form button {
      border-radius: 25px;
      margin: 1em 0;
      width: 100%;
      padding: 1.1em;
      font-size: 1em;
      outline: none; }
      .login__form button:active {
        background: #781da1; }
      .login__form button:disabled {
        background: #dddddd;
        color: gray; }
    .login__form .nextLoading {
      position: absolute;
      bottom: 32px; }
    .login__form .radiogroup {
      display: flex;
      flex-direction: row;
      padding: 0.3em;
      margin-bottom: 1em;
      border: 1.5px solid gray; }

.authmodalcontainer {
  display: inline;
  background: transparent; }
  .authmodalcontainer__modal {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto; }
    .authmodalcontainer__modal .container {
      outline: none;
      border: none;
      position: relative;
      background: #fff;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
      height: 80vh;
      padding: 0; }
      .authmodalcontainer__modal .container .backBtn {
        position: absolute;
        padding: 1em;
        top: 1em;
        cursor: pointer; }
      .authmodalcontainer__modal .container .welcome {
        padding: 2.5em;
        margin: 0;
        width: 100%;
        height: 80vh;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
        position: relative; }
        .authmodalcontainer__modal .container .welcome #logo {
          position: absolute;
          top: 2.5em;
          left: 2.5em;
          padding: 0;
          height: 60px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .authmodalcontainer__modal .container .welcome #logo img {
            width: 200px;
            height: auto; }
          .authmodalcontainer__modal .container .welcome #logo h1 {
            padding: 0;
            margin: 0;
            display: inline-block; }
        .authmodalcontainer__modal .container .welcome #coaching {
          width: 50%;
          height: auto;
          position: absolute;
          top: 2em;
          right: 2em; }
        .authmodalcontainer__modal .container .welcome #teacher {
          width: 40%;
          height: auto;
          position: absolute;
          bottom: 2em;
          right: 3em; }
        .authmodalcontainer__modal .container .welcome h1 {
          position: relative;
          margin-top: 36%;
          font-size: 2.5em;
          z-index: 999; }
        .authmodalcontainer__modal .container .welcome h2 {
          margin-bottom: 2em; }
        .authmodalcontainer__modal .container .welcome .signup-link-text {
          position: absolute;
          bottom: 30px; }
      .authmodalcontainer__modal .container .form {
        padding: 2em;
        margin: 0;
        width: 100%;
        height: 100%;
        width: 100%;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center; }
      .authmodalcontainer__modal .container .cancel-btn {
        position: absolute;
        right: 1em;
        top: 1em;
        cursor: pointer; }

.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.purple {
  color: #8657f3; }

.pink {
  color: #ea1bac; }

.rotate180 {
  transform: rotate(180deg); }

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 9999; }
  .nav__logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #242323;
    margin-left: 2em; }
    .nav__logo img {
      width: 50px;
      height: auto;
      margin: 0 1em; }
  .nav__links {
    margin-right: 2em; }
    .nav__links a {
      padding: 0.4rem 1.8rem;
      font-size: 1.2em;
      text-decoration: none;
      color: #fff;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      text-shadow: -5px 3px 8px #969696; }
      .nav__links a span {
        background: transparent; }
      .nav__links a:last-child {
        background: #fff;
        color: #8657f3;
        padding: 0.2rem 1rem;
        border-radius: 28px;
        border: 2px solid #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        text-shadow: none; }
        .nav__links a:last-child:hover {
          background: #8657f3;
          color: #fff;
          border-color: #8657f3;
          box-shadow: 0 3px 6px rgba(63, 5, 70, 0.16), 0 3px 6px rgba(64, 4, 97, 0.23); }

.landing {
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  color: #221327;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative; }
  .landing::before {
    content: "";
    z-index: -1;
    background: #c73333;
    background: linear-gradient(0deg, white 0%, rgba(247, 246, 246, 0.789627) 0%, #d4d2d2 100%);
    background-image: url("/icons/landing/upper_bg.svg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 130%;
    position: absolute;
    left: 0;
    top: -10%;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .landing__top .left {
    padding: 2.5em;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    align-items: flex-start; }
    .landing__top .left h2 {
      font-weight: 300; }
    .landing__top .left .input {
      background: white;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      height: 48px;
      width: 100%;
      max-width: 450px; }
      .landing__top .left .input input {
        border: none;
        border-radius: 25px;
        outline: none;
        padding: 1.1em 1em;
        font-size: 1rem;
        background: transparent;
        width: 80%;
        height: 100%; }
      .landing__top .left .input img {
        height: 48px;
        width: auto;
        cursor: pointer; }
  .landing__top .right {
    width: 100%;
    height: 100%; }
  .landing__top2 {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 3em; }
    .landing__top2 .innerBox {
      background: white;
      background: linear-gradient(90deg, white 0%, #4853ef 0%, #ec00ff 100%);
      border-radius: 15px;
      width: 100%;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
      padding: 1em;
      height: 130px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff; }
  .landing__section {
    width: 90%;
    max-width: 1200px;
    margin: 1em auto; }
    .landing__section h2 {
      font-size: 2.5em;
      padding: 1em;
      font-weight: 400;
      padding-bottom: 0;
      margin-bottom: 0; }
    .landing__section .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .landing__section .col p {
        padding: 2em;
        line-height: 1.6; }
    .landing__section .left {
      text-align: left;
      align-items: flex-start; }
    .landing__section .right {
      text-align: right;
      align-items: flex-end; }
  .landing__subscribeSection {
    width: 90%;
    max-width: 1200px;
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center; }
    .landing__subscribeSection h2,
    .landing__subscribeSection h3 {
      text-align: center;
      color: #2f2d2d; }
    .landing__subscribeSection h3 {
      font-weight: normal; }
    .landing__subscribeSection .emailbox {
      background-color: #d8d8d8;
      border-radius: 8px;
      width: 100%;
      max-width: 765px;
      height: 68px;
      overflow: hidden;
      margin: 2em auto; }
      .landing__subscribeSection .emailbox input {
        width: 80%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        padding: 6px 1em; }
      .landing__subscribeSection .emailbox button {
        width: 20%;
        height: 100%;
        background-color: #130242;
        border-radius: 8px;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer; }
    .landing__subscribeSection .testimonialbox {
      background-image: linear-gradient(109.54deg, #8468f5 0%, #ec74e7 100%);
      border-radius: 12px;
      box-shadow: inset 0 36px 43px rgba(0, 0, 0, 0.2);
      width: 845px;
      height: 173px;
      overflow: hidden;
      margin: 2em auto;
      position: relative;
      padding: 1em 3em; }
      .landing__subscribeSection .testimonialbox .controlDots {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 30px;
        position: absolute;
        right: 20px;
        top: 0; }
        .landing__subscribeSection .testimonialbox .controlDots span {
          width: 13px;
          height: 13px;
          margin: 4px;
          border-radius: 50%;
          background: transparent;
          border: 2px solid #fff;
          cursor: pointer; }
        .landing__subscribeSection .testimonialbox .controlDots .activeDot {
          background: #fff; }
      .landing__subscribeSection .testimonialbox .upper {
        margin-top: 1em;
        padding-left: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start; }
        .landing__subscribeSection .testimonialbox .upper p {
          margin: 0 1em;
          color: #fff; }
          .landing__subscribeSection .testimonialbox .upper p p {
            font-size: 12px;
            position: relative;
            left: -10px;
            font-weight: normal;
            padding-top: 2px; }
      .landing__subscribeSection .testimonialbox .lower {
        font-size: 0.9em;
        padding: 0 1em; }
        .landing__subscribeSection .testimonialbox .lower p {
          padding: 1em;
          color: #fff; }
  .landing__footer {
    width: 100%;
    max-width: 1200px;
    border-top: 2px solid #544837;
    padding: 2em 1em; }
    .landing__footer .logo,
    .landing__footer .social,
    .landing__footer .links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .landing__footer .logo a,
      .landing__footer .social a,
      .landing__footer .links a {
        text-decoration: none;
        font-size: 1.3em;
        margin: 0.3rem 0;
        color: #544837; }
    .landing__footer .logo img {
      width: 180px;
      height: auto;
      padding: 1em; }
    .landing__footer .social svg {
      position: relative;
      bottom: -5px; }
    .landing__footer .social .linkedin {
      color: #0077b7; }
    .landing__footer .social .facebook {
      color: #2196f3; }
    .landing__footer .social .twitter {
      color: #03a9f4; }
    .landing__footer .footerImage {
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      text-align: center;
      margin: 0; }
      .landing__footer .footerImage img {
        width: 100%;
        height: 100%; }

.cardCarousel {
  width: 60%;
  height: 340px;
  position: absolute;
  right: -100px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .cardCarousel .card {
    height: 90%;
    width: 100px;
    background: #fff;
    margin: 1em;
    border-radius: 20px;
    box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.38); }
  .cardCarousel .b2 {
    height: 60%;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-55%);
    opacity: 0.7; }
  .cardCarousel .b1 {
    height: 75%;
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-55%); }
  .cardCarousel .f1,
  .cardCarousel .f2,
  .cardCarousel .f3 {
    padding: 2em;
    position: relative;
    left: 100px;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.38), inset 0px -5px 8px 9px white; }
    .cardCarousel .f1 .avatarContainer,
    .cardCarousel .f2 .avatarContainer,
    .cardCarousel .f3 .avatarContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;
      height: 30%; }
      .cardCarousel .f1 .avatarContainer p,
      .cardCarousel .f2 .avatarContainer p,
      .cardCarousel .f3 .avatarContainer p {
        padding-left: 8px; }
    .cardCarousel .f1 .textContainer,
    .cardCarousel .f2 .textContainer,
    .cardCarousel .f3 .textContainer {
      font-weight: normal;
      font-size: 13px;
      line-height: 1.5;
      overflow: hidden;
      color: #5e5069; }

@media only screen and (max-width: 1024px) {
  .cardCarousel {
    display: none; } }

.dashboard {
  position: relative;
  padding: 0em;
  z-index: 888; }
  .dashboard__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start; }
    .dashboard__nav a {
      color: unset;
      text-decoration: none;
      padding: 1em;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;
      color: #474747; }
      .dashboard__nav a:hover {
        background: rgba(0, 0, 0, 0.115); }
      .dashboard__nav a span {
        padding: 0 1em; }
    .dashboard__nav .active {
      background: #732cbe;
      color: #fff; }
      .dashboard__nav .active:hover {
        background: #732cbe;
        opacity: 0.9; }
  .dashboard .card {
    height: 100%;
    max-height: 250px; }
    .dashboard .card .cardContent {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto; }
      .dashboard .card .cardContent::-webkit-scrollbar {
        width: 6px; }
      .dashboard .card .cardContent::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      .dashboard .card .cardContent::-webkit-scrollbar-thumb {
        background-color: #8a00c15e;
        outline: 1px solid #b033ea; }
  .dashboard__topRow .welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%; }
    .dashboard__topRow .welcome h1,
    .dashboard__topRow .welcome h2 {
      margin: 0;
      text-align: center; }
  .dashboard__topRow .createClassroom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%; }
    .dashboard__topRow .createClassroom button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: white;
      background: linear-gradient(115deg, white 0%, #a283ff 0%, #341c86 100%);
      width: 100%;
      height: 100%;
      color: #fff; }
      .dashboard__topRow .createClassroom button svg {
        width: 50px;
        height: 50px; }
      .dashboard__topRow .createClassroom button h2 {
        margin: 0;
        text-transform: capitalize; }
  .dashboard__topRow .paymentCard {
    position: relative;
    height: 100%; }
    .dashboard__topRow .paymentCard .MuiCardHeader-root {
      padding: 0.5em 1em; }
    .dashboard__topRow .paymentCard .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between; }
      .dashboard__topRow .paymentCard .header .moneyCard {
        margin: 2px;
        padding: 0; }
        .dashboard__topRow .paymentCard .header .moneyCard button {
          padding: 8px;
          margin: 0;
          border-radius: 8px; }
        .dashboard__topRow .paymentCard .header .moneyCard .active {
          border: 2px solid orange;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .dashboard__bottomRow .classroomCard {
    position: relative;
    height: 100%;
    max-height: 550px; }
    .dashboard__bottomRow .classroomCard .MuiCardHeader-root {
      padding: 1em; }
    .dashboard__bottomRow .classroomCard .classroomItem {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5em 0; }
      .dashboard__bottomRow .classroomCard .classroomItem__left {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0; }
        .dashboard__bottomRow .classroomCard .classroomItem__left .icon {
          background: #732cbe;
          border-radius: 8px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          color: #fff; }
      .dashboard__bottomRow .classroomCard .classroomItem__right {
        display: flex;
        justify-content: center;
        align-items: center; }
        .dashboard__bottomRow .classroomCard .classroomItem__right button {
          margin: 0 0.5em;
          text-transform: capitalize;
          font-size: 13px; }
      .dashboard__bottomRow .classroomCard .classroomItem__weekView {
        flex-direction: column;
        padding-bottom: 1em;
        border-bottom: 2px solid lightgray;
        width: 100%;
        margin: 0;
        margin-bottom: 1em; }
        .dashboard__bottomRow .classroomCard .classroomItem__weekView h5 {
          margin: 0;
          font-weight: bold;
          margin: 0.5em 0;
          font-size: 1.1em; }
        .dashboard__bottomRow .classroomCard .classroomItem__weekView .week {
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: space-between; }
          .dashboard__bottomRow .classroomCard .classroomItem__weekView .week .day {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 9px;
            margin: 0 2px; }
            .dashboard__bottomRow .classroomCard .classroomItem__weekView .week .day .active {
              color: #fff;
              background: lightseagreen; }
  .dashboard__bottomRow .scheduleCard {
    position: relative;
    height: 100%;
    max-height: 550px; }
    .dashboard__bottomRow .scheduleCard .MuiCardHeader-root {
      padding: 0.5em 1em; }
    .dashboard__bottomRow .scheduleCard .scheduleItem {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 4px solid #732cbe; }
      .dashboard__bottomRow .scheduleCard .scheduleItem__left {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0; }
        .dashboard__bottomRow .scheduleCard .scheduleItem__left .icon {
          background: #732cbe;
          border-radius: 8px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          color: #fff; }
      .dashboard__bottomRow .scheduleCard .scheduleItem__bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5em 0;
        border-bottom: 1px solid lightgray;
        margin-bottom: 1em; }
        .dashboard__bottomRow .scheduleCard .scheduleItem__bottom button {
          margin: 0 0.5em;
          text-transform: capitalize; }
  .dashboard__bottomRow .assignmentCard {
    position: relative;
    height: 100%;
    max-height: 550px; }
    .dashboard__bottomRow .assignmentCard .assignmentItem {
      padding: 1em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid lightgray; }
      .dashboard__bottomRow .assignmentCard .assignmentItem__left .icon {
        background: #732cbe;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        color: #fff; }
      .dashboard__bottomRow .assignmentCard .assignmentItem__center p {
        position: relative; }
        .dashboard__bottomRow .assignmentCard .assignmentItem__center p span {
          position: absolute;
          right: 0;
          font-weight: normal; }
      .dashboard__bottomRow .assignmentCard .assignmentItem__right {
        display: flex;
        justify-content: center;
        align-items: center; }
        .dashboard__bottomRow .assignmentCard .assignmentItem__right button {
          text-transform: capitalize; }

.paymentPeople {
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.07);
  padding: 0.5em;
  border-radius: 8px;
  margin: 0.5em auto;
  font-weight: bold; }
  .paymentPeople__left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    color: gray; }
    .paymentPeople__left .dateData {
      padding: 0;
      padding-right: 4px; }
  .paymentPeople__center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    font-size: 0.8em;
    color: gray;
    padding: 0 0.5em;
    padding-left: 1em; }
    .paymentPeople__center .orange {
      color: #ff5100; }
  .paymentPeople__right {
    display: flex;
    justify-content: center;
    align-items: center; }
    .paymentPeople__right button {
      font-size: 0.8em;
      text-transform: capitalize;
      padding: 2px 4px; }

.payment {
  margin-top: -1em;
  position: relative;
  z-index: 888; }
  .payment__dataHeader {
    padding: 1em 0; }
    .payment__dataHeader .blueBox {
      padding: 1em;
      height: 110px;
      background: linear-gradient(115deg, white 0%, #a283ff 0%, #341c86 100%);
      border-radius: 8px;
      box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.2); }
      .payment__dataHeader .blueBox .heading {
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: start; }
        .payment__dataHeader .blueBox .heading button {
          text-transform: capitalize;
          background: #fff; }
      .payment__dataHeader .blueBox .body {
        font-size: 2.5em;
        font-weight: bold;
        color: #fff; }
        .payment__dataHeader .blueBox .body button {
          text-transform: capitalize;
          background: #fff; }
  .payment__dataContainer .dataRow {
    height: 300px;
    margin: 1em 0; }
    .payment__dataContainer .dataRow .leftMoney {
      position: relative;
      padding: 1em;
      height: 100%; }
      .payment__dataContainer .dataRow .leftMoney__left .dataContainer {
        display: flex;
        margin-top: 2em; }
        .payment__dataContainer .dataRow .leftMoney__left .dataContainer .icon {
          width: 20%;
          height: 45px;
          border-left: 4px solid #732cbe;
          display: flex;
          justify-content: center;
          align-items: center; }
          .payment__dataContainer .dataRow .leftMoney__left .dataContainer .icon span {
            background: #732cbe;
            width: 40px;
            height: 40px;
            color: #fff;
            padding: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px; }
        .payment__dataContainer .dataRow .leftMoney__left .dataContainer .studentDetails {
          width: 80%; }
          .payment__dataContainer .dataRow .leftMoney__left .dataContainer .studentDetails h4 {
            margin: 0;
            font-size: 1.3em; }
          .payment__dataContainer .dataRow .leftMoney__left .dataContainer .studentDetails p {
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: flex-start; }
            .payment__dataContainer .dataRow .leftMoney__left .dataContainer .studentDetails p svg {
              width: 0.7em;
              height: 0.7em; }
      .payment__dataContainer .dataRow .leftMoney__left .btnContainer {
        position: absolute;
        bottom: 3em;
        left: 1em;
        width: 100%; }
        .payment__dataContainer .dataRow .leftMoney__left .btnContainer button {
          background: #fff;
          text-transform: capitalize;
          border: 1px solid rgba(105, 37, 151, 0.516); }
      .payment__dataContainer .dataRow .leftMoney__right {
        position: absolute;
        right: 0;
        top: 0;
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly; }
        .payment__dataContainer .dataRow .leftMoney__right button {
          border: 2px solid rgba(250, 148, 101, 0.938);
          border-radius: 8px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
        .payment__dataContainer .dataRow .leftMoney__right .activeBtn {
          border: 2px solid #732cbe; }
    .payment__dataContainer .dataRow .paidPeopleList .header {
      height: 40px;
      font-weight: bold;
      padding: 0.5em;
      padding-top: 0.8em;
      white-space: nowrap; }
      .payment__dataContainer .dataRow .paidPeopleList .header .col {
        display: flex;
        justify-content: center;
        align-items: center; }
    .payment__dataContainer .dataRow .paidPeopleList .body {
      height: 260px;
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .payment__dataContainer .dataRow .paidPeopleList .body::-webkit-scrollbar {
        width: 6px; }
      .payment__dataContainer .dataRow .paidPeopleList .body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      .payment__dataContainer .dataRow .paidPeopleList .body::-webkit-scrollbar-thumb {
        background-color: #8a00c15e;
        outline: 1px solid #b033ea; }

.historyModal #customized-dialog-title .balance {
  display: flex;
  justify-content: space-between; }
  .historyModal #customized-dialog-title .balance div {
    margin-left: 20%; }
  .historyModal #customized-dialog-title .balance h2 {
    font-weight: bold;
    font-size: 2.5em;
    margin: 0; }

.historyModal .table {
  padding: 0 1em; }
  .historyModal .table .col {
    text-align: left; }
  .historyModal .table .header {
    width: 100%;
    padding-bottom: 0.8em;
    border-bottom: 1px solid gray; }
    .historyModal .table .header .col {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start; }
    .historyModal .table .header p {
      font-weight: bold;
      font-size: 1.2em; }
  .historyModal .table .body {
    width: 100%; }
    .historyModal .table .body .row {
      width: 100%;
      padding: 1em 0;
      border-bottom: 1px solid lightgray; }
    .historyModal .table .body .col {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start; }
    .historyModal .table .body .name {
      padding-left: 1em; }

.MoneyInfo {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  min-width: 100px;
  height: 100%;
  margin: 0; }
  .MoneyInfo .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: flex-start; }
    .MoneyInfo .left .head {
      font-size: 1em;
      font-weight: bold; }
    .MoneyInfo .left .subhead {
      font-size: 0.7em;
      font-weight: bold;
      text-transform: capitalize;
      color: #414141; }
  .MoneyInfo .right {
    padding: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 0.5em;
    background: #ecffff; }
    .MoneyInfo .right .MuiBox-root {
      width: 100%;
      height: 100%; }
      .MoneyInfo .right .MuiBox-root .MuiTypography-root {
        font-size: 9px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center; }

.paidPeopleListItem {
  width: 98%;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.07);
  padding: 0.5em;
  border-radius: 8px;
  margin: 0.5em auto;
  font-weight: bold; }
  .paidPeopleListItem__time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    color: gray; }
    .paidPeopleListItem__time .dateData {
      padding: 0;
      padding-right: 10px; }
  .paidPeopleListItem__name {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start; }
    .paidPeopleListItem__name .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      font-size: 0.8em;
      color: gray;
      padding: 0 0.5em;
      padding-left: 1em; }
      .paidPeopleListItem__name .content .orange {
        color: #ff5100; }
  .paidPeopleListItem__mode {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    color: gray; }
  .paidPeopleListItem__btn {
    display: flex;
    justify-content: center;
    align-items: center; }
    .paidPeopleListItem__btn button {
      font-size: 0.8em;
      text-transform: capitalize;
      padding: 2px 4px; }

.studentPaymentLeftCard {
  padding: 1em;
  border-radius: 15px !important;
  position: relative;
  height: 230px !important; }
  .studentPaymentLeftCard .dueIcon {
    position: absolute;
    top: 2em;
    right: 2em;
    cursor: pointer; }

.detailsForm {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(146, 40, 228, 0.414);
  padding: 2em;
  padding-top: 6em; }
  .detailsForm__mainRow {
    height: 100%;
    width: 100%; }
    .detailsForm__mainRow .left {
      height: 100%;
      width: 90%;
      max-width: 600px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center; }
      .detailsForm__mainRow .left img {
        height: 100%;
        width: 100%; }
    .detailsForm__mainRow .right {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%; }
  .detailsForm__card {
    height: 100%;
    width: 100%;
    overflow: auto !important;
    border-radius: 15px;
    padding: 1em;
    width: 100%;
    max-width: 500px;
    position: relative; }
    .detailsForm__card::-webkit-scrollbar {
      width: 6px; }
    .detailsForm__card::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    .detailsForm__card::-webkit-scrollbar-thumb {
      background-color: #8a00c15e;
      outline: 1px solid #b033ea; }
    .detailsForm__card .header {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      top: 0;
      left: 0; }
      .detailsForm__card .header img {
        width: 100px;
        height: auto; }
      .detailsForm__card .header .stepIndicator {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        width: 120px;
        position: relative; }
        .detailsForm__card .header .stepIndicator span {
          background: #ebd3fb;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          font-size: 9px;
          color: gray;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center; }
        .detailsForm__card .header .stepIndicator .active {
          background: #ce87fd;
          width: 22px;
          height: 22px;
          border: 2px solid #732cbe;
          font-size: 13px;
          color: #732cbe; }
        .detailsForm__card .header .stepIndicator::before {
          content: "";
          width: 100%;
          height: 2px;
          background: #732cbe;
          position: absolute;
          z-index: -1; }
    .detailsForm__card .body {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .detailsForm__card .body .avatar {
        width: 90px;
        height: 90px;
        color: #732cbe;
        background: #d598f6; }
      .detailsForm__card .body form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        position: relative;
        padding: 0 1em; }
        .detailsForm__card .body form .text-left,
        .detailsForm__card .body form small {
          text-align: left;
          width: 100%;
          margin-bottom: -1em; }
        .detailsForm__card .body form small {
          color: gray; }
        .detailsForm__card .body form .input {
          width: 100%;
          height: 40px;
          margin: 0 auto; }
        .detailsForm__card .body form .groupOfTwo {
          width: 100%;
          display: flex !important;
          justify-content: space-between !important; }
          .detailsForm__card .body form .groupOfTwo .MuiInputBase-root {
            width: 100% !important; }
        .detailsForm__card .body form .radiogroup {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%; }
          .detailsForm__card .body form .radiogroup .MuiFormGroup-root {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 1em;
            width: 100% !important; }
      .detailsForm__card .body button:disabled {
        background: #dddddd;
        color: gray; }
      .detailsForm__card .body .submitLoading {
        position: relative;
        bottom: 2em; }

.classroom {
  margin-top: -1em;
  position: relative;
  z-index: 888; }
  .classroom__body {
    padding-top: 1.5em;
    padding: 1em; }
    .classroom__body .classCardRow .classCard {
      margin: 1em;
      padding: 1em; }
    .classroom__body .formContainer {
      padding: 1em; }

.classData {
  position: relative;
  height: 100%; }
  .classData__id {
    display: flex;
    justify-content: flex-end;
    color: gray;
    font-weight: bold; }
  .classData__dataContainer {
    display: flex;
    margin-top: 0.5em; }
    .classData__dataContainer .icon {
      width: 20%;
      height: 45px;
      border-left: 4px solid #732cbe;
      display: flex;
      justify-content: center;
      align-items: center; }
      .classData__dataContainer .icon span {
        background: #732cbe;
        width: 40px;
        height: 40px;
        color: #fff;
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px; }
    .classData__dataContainer .studentDetails {
      width: 80%; }
      .classData__dataContainer .studentDetails h4 {
        margin: 0;
        font-size: 1.3em; }
      .classData__dataContainer .studentDetails p {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: flex-start; }
        .classData__dataContainer .studentDetails p svg {
          width: 0.7em;
          height: 0.7em; }
  .classData__calendar {
    flex-direction: column;
    width: 100%;
    margin: 0;
    margin-bottom: 1em; }
    .classData__calendar h5 {
      margin: 0;
      font-weight: bold;
      margin: 0.5em 0;
      font-size: 1.1em; }
    .classData__calendar .week {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-between; }
      .classData__calendar .week .day {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 2px; }
        .classData__calendar .week .day .MuiAvatar-root {
          width: 28px;
          height: 28px;
          font-size: 1em; }
        .classData__calendar .week .day .active {
          color: #fff;
          background: lightseagreen; }
  .classData__btnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    .classData__btnContainer button {
      background: #fff;
      text-transform: capitalize;
      border: 1px solid rgba(105, 37, 151, 0.516); }

.createClassRommForm {
  position: relative; }
  .createClassRommForm__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .createClassRommForm__header h3 {
      text-align: center;
      font-size: 1.5em;
      padding: 0.5em 0;
      margin-bottom: 0; }
  .createClassRommForm__body {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .createClassRommForm__body .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 300px;
      max-width: 100%;
      position: relative; }
      .createClassRommForm__body .form .input {
        min-width: 300px;
        max-width: 100%;
        height: 40px; }
      .createClassRommForm__body .form .feeRow {
        min-width: 300px;
        max-width: 100%;
        display: flex;
        justify-content: space-between; }
        .createClassRommForm__body .form .feeRow #fee {
          width: 140px; }
        .createClassRommForm__body .form .feeRow .periodSelect {
          height: 40px; }
      .createClassRommForm__body .form .createClassbtn {
        background: #732cbe;
        color: #fff; }
      .createClassRommForm__body .form button:disabled {
        background: #dddddd;
        color: gray; }
      .createClassRommForm__body .form .loadingIcon {
        position: absolute;
        bottom: 0.5em; }

.addStudentModal #customized-dialog-title {
  padding: 2em; }
  .addStudentModal #customized-dialog-title h2 {
    margin: 0;
    font-weight: normal; }
  .addStudentModal #customized-dialog-title .heading {
    display: flex;
    justify-content: flex-start;
    margin-top: 1em; }
    .addStudentModal #customized-dialog-title .heading .icon {
      height: 50px;
      border-left: 6px solid #732cbe;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.6em; }
      .addStudentModal #customized-dialog-title .heading .icon span {
        background: #732cbe;
        width: 50px;
        height: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px; }
    .addStudentModal #customized-dialog-title .heading .details h4 {
      font-weight: bold;
      font-size: 1em;
      margin: 0;
      margin-top: -6px; }
    .addStudentModal #customized-dialog-title .heading .details p {
      font-size: 0.8em; }

.addStudentModal .body {
  width: 100%;
  padding: 0 2em; }
  .addStudentModal .body .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .addStudentModal .body .form .input,
    .addStudentModal .body .form .phoneInput {
      margin: 1em; }
    .addStudentModal .body .form #name {
      min-width: 250px; }
    .addStudentModal .body .form .phoneInput {
      max-width: 300px; }
      .addStudentModal .body .form .phoneInput .form-control {
        width: 100%;
        height: 100%; }
  .addStudentModal .body .submitContainer {
    display: flex;
    align-items: center; }
    .addStudentModal .body .submitContainer button {
      background: #732cbe;
      color: #fff;
      margin: 1em 0; }
      .addStudentModal .body .submitContainer button:disabled {
        background: lightgray;
        color: gray; }

.classroomView {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 888;
  height: calc(100vh - 80px); }
  .classroomView__backBtn {
    background: #fff !important;
    color: #252424 !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
    width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 10; }
    .classroomView__backBtn:active {
      background: #eaeae9 !important; }
  .classroomView__body {
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    height: 100%; }
    .classroomView__body .col {
      height: 100%; }
    .classroomView__body .contentContainer {
      padding: 1em;
      height: 100%;
      overflow: auto; }
      .classroomView__body .contentContainer::-webkit-scrollbar {
        width: 6px; }
      .classroomView__body .contentContainer::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      .classroomView__body .contentContainer::-webkit-scrollbar-thumb {
        background-color: #8a00c15e;
        outline: 1px solid #b033ea; }
    .classroomView__body .classCard {
      margin: 1em 0;
      padding: 3em 1em;
      cursor: pointer; }
      .classroomView__body .classCard:active {
        background: #f0eeee; }
    .classroomView__body #activeCard {
      border-bottom: 4px solid #732cbe; }
    .classroomView__body .rightCard {
      height: 100%; }
      .classroomView__body .rightCard .classDetailCard {
        height: 100%;
        padding-bottom: 0; }
        .classroomView__body .rightCard .classDetailCard .top {
          height: 45%;
          overflow: auto;
          position: relative;
          font-size: 11px;
          padding-top: 0;
          margin: 0; }
          .classroomView__body .rightCard .classDetailCard .top::-webkit-scrollbar {
            width: 6px; }
          .classroomView__body .rightCard .classDetailCard .top::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
          .classroomView__body .rightCard .classDetailCard .top::-webkit-scrollbar-thumb {
            background-color: #8a00c15e;
            outline: 1px solid #b033ea; }
          .classroomView__body .rightCard .classDetailCard .top .topBtn {
            background: #fff;
            text-transform: capitalize; }
          .classroomView__body .rightCard .classDetailCard .top .joinBtn {
            background: #732cbe;
            color: #eee; }
          .classroomView__body .rightCard .classDetailCard .top .classData__id {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            padding: 1px 1em;
            font-size: 9px; }
          .classroomView__body .rightCard .classDetailCard .top .classData__calendar,
          .classroomView__body .rightCard .classDetailCard .top .classData__dataContainer {
            width: 100%;
            max-width: 400px; }
          .classroomView__body .rightCard .classDetailCard .top h5 {
            margin: 0;
            font-size: 1.1em;
            padding-bottom: 8px; }
          .classroomView__body .rightCard .classDetailCard .top .description {
            padding: 0.5em;
            padding-left: 0;
            max-height: 60px;
            overflow: auto;
            border-radius: 8px;
            margin: 0;
            margin-top: -8px;
            height: 50px; }
            .classroomView__body .rightCard .classDetailCard .top .description::-webkit-scrollbar {
              width: 6px; }
            .classroomView__body .rightCard .classDetailCard .top .description::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
            .classroomView__body .rightCard .classDetailCard .top .description::-webkit-scrollbar-thumb {
              background-color: #8a00c15e;
              outline: 1px solid #b033ea; }
        .classroomView__body .rightCard .classDetailCard .bottom {
          height: 55%;
          overflow: auto;
          overflow-x: hidden; }
          .classroomView__body .rightCard .classDetailCard .bottom::-webkit-scrollbar {
            width: 6px; }
          .classroomView__body .rightCard .classDetailCard .bottom::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
          .classroomView__body .rightCard .classDetailCard .bottom::-webkit-scrollbar-thumb {
            background-color: #8a00c15e;
            outline: 1px solid #b033ea; }
          .classroomView__body .rightCard .classDetailCard .bottom .studentList {
            width: 100%;
            height: 100%; }
            .classroomView__body .rightCard .classDetailCard .bottom .studentList .table {
              position: relative; }
              .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .tableTop {
                padding: 0 1em 1em 1em;
                display: flex;
                justify-content: center;
                align-items: center;
                justify-content: space-between; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .tableTop h5 {
                  margin: 0;
                  font-size: 1.5em;
                  margin: 0.5em 0; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .tableTop button {
                  background: #fff;
                  margin: 0;
                  height: 20px;
                  text-transform: capitalize;
                  margin-right: 1em; }
              .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .col {
                text-align: left; }
              .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header {
                width: 100%;
                padding-bottom: 0.6em;
                border-bottom: 1px solid gray;
                background: #fff;
                font-size: 10px;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                left: 0;
                z-index: 10; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header .col {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  justify-content: flex-start; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header p {
                  font-weight: bold;
                  font-size: 1.2em; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header .totalCheck {
                  padding: 0;
                  margin: 0;
                  margin-left: 0.3em; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header .headerRow {
                  padding: 0 1em;
                  padding-top: 0;
                  padding-bottom: 0; }
                  .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header .headerRow .col {
                    padding-left: 0; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header .searchInput {
                  padding: 0 1em;
                  border-radius: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                  .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .header .searchInput input {
                    font-size: 0.8em;
                    border: none !important;
                    outline: none; }
              .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .body {
                width: 100%; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .body .row {
                  width: 100%;
                  padding: 0.5em 0;
                  border-bottom: 1px solid lightgray;
                  font-size: 0.5px;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .body .col {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  justify-content: flex-start;
                  align-items: center; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .body .name {
                  padding-left: 1em; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .body .avatar {
                  width: 30px;
                  height: 30px; }
                .classroomView__body .rightCard .classDetailCard .bottom .studentList .table .body .viewBtn {
                  text-transform: capitalize;
                  padding: 0; }

.studentDetailsModal__left .top {
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
  .studentDetailsModal__left .top .avatar {
    width: 120px;
    height: 120px; }
  .studentDetailsModal__left .top h3 {
    margin: 0;
    font-size: 1.5em; }

.studentDetailsModal__left .details p {
  color: #383838;
  font-size: 1.1em;
  font-weight: bold;
  padding: 0.5em 1em; }

.studentDetailsModal__right {
  height: 600px; }
  .studentDetailsModal__right .top {
    height: 250px;
    overflow: auto;
    overflow-x: hidden;
    position: relative; }
    .studentDetailsModal__right .top::-webkit-scrollbar {
      width: 6px; }
    .studentDetailsModal__right .top::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    .studentDetailsModal__right .top::-webkit-scrollbar-thumb {
      background-color: #8a00c15e;
      outline: 1px solid #b033ea; }
    .studentDetailsModal__right .top h3 {
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      left: 0;
      top: -1px;
      background: #fff;
      padding: 4px; }
    .studentDetailsModal__right .top .classCard {
      padding: 0.5em;
      cursor: pointer; }
      .studentDetailsModal__right .top .classCard:active {
        background: #f6f5f5; }
  .studentDetailsModal__right .bottom {
    height: 350px;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    padding: 0 1em; }
    .studentDetailsModal__right .bottom::-webkit-scrollbar {
      width: 6px; }
    .studentDetailsModal__right .bottom::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    .studentDetailsModal__right .bottom::-webkit-scrollbar-thumb {
      background-color: #8a00c15e;
      outline: 1px solid #b033ea; }
    .studentDetailsModal__right .bottom h3 {
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      left: 0;
      top: -1px;
      background: #fff;
      padding: 4px;
      margin: 0;
      z-index: 10; }
    .studentDetailsModal__right .bottom .paymentPeople__right {
      font-weight: normal;
      font-size: 13px; }

.student_classCard_dataContainer {
  position: relative; }
  .student_classCard_dataContainer .schedule-text {
    color: #732cbe;
    font-size: 1.2em;
    font-weight: bold;
    position: absolute;
    right: 1em;
    bottom: -1em; }

.headerTop {
  padding: 1em;
  padding-top: 1.5em;
  display: flex;
  align-items: center;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  left: 0;
  z-index: 100; }
  .headerTop h1 {
    margin: 0;
    padding-left: 1em;
    font-size: 1.5em; }
  .headerTop .flex-grow {
    flex-grow: 1; }

.assignment {
  margin: 0;
  margin-top: -1em;
  position: relative;
  z-index: 888; }
  .assignment__body {
    padding-top: 1.5em;
    padding: 1em; }
    .assignment__body .assignmentCardRow .assignementCard {
      margin: 1em;
      padding: 1em; }
    .assignment__body .formContainer {
      padding: 1em; }

.assignmentData {
  position: relative;
  height: 100%; }
  .assignmentData__id {
    display: flex;
    justify-content: flex-end;
    color: gray;
    font-weight: bold; }
  .assignmentData__dataContainer {
    display: flex;
    margin-top: 0.5em;
    position: relative; }
    .assignmentData__dataContainer .header {
      position: absolute;
      top: -2em;
      right: 0; }
    .assignmentData__dataContainer .icon {
      width: 20%;
      height: 45px;
      border-left: 4px solid #732cbe;
      display: flex;
      justify-content: center;
      align-items: center; }
      .assignmentData__dataContainer .icon span {
        background: #732cbe;
        width: 40px;
        height: 40px;
        color: #fff;
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px; }
    .assignmentData__dataContainer .studentDetails {
      width: 80%; }
      .assignmentData__dataContainer .studentDetails h4 {
        margin: 0;
        font-size: 1.3em; }
      .assignmentData__dataContainer .studentDetails p {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: flex-start; }
        .assignmentData__dataContainer .studentDetails p svg {
          width: 0.7em;
          height: 0.7em; }
  .assignmentData__details {
    flex-direction: column;
    width: 100%;
    margin: 0;
    margin-bottom: 1em; }
    .assignmentData__details h5 {
      margin: 0;
      font-weight: bold;
      margin: 0.5em 0;
      font-size: 1.1em; }
      .assignmentData__details h5 span {
        font-weight: normal;
        margin-left: 0.6em; }
  .assignmentData__btnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    .assignmentData__btnContainer button {
      background: #fff;
      text-transform: capitalize;
      border: 1px solid rgba(105, 37, 151, 0.516); }

.createAssignmentForm {
  position: relative; }
  .createAssignmentForm__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .createAssignmentForm__header h3 {
      text-align: center;
      font-size: 1.5em;
      padding: 0.5em 0;
      margin-bottom: 0; }
  .createAssignmentForm__body {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .createAssignmentForm__body .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 300px;
      max-width: 100%;
      position: relative; }
      .createAssignmentForm__body .form .input {
        min-width: 300px;
        max-width: 100%;
        height: 40px; }
      .createAssignmentForm__body .form .upload {
        min-width: 300px;
        max-width: 100%; }
        .createAssignmentForm__body .form .upload .MuiDropzoneArea-root {
          width: 100%;
          min-width: 300px;
          max-width: 100%;
          position: relative; }
          .createAssignmentForm__body .form .upload .MuiDropzoneArea-root .MuiDropzoneArea-textContainer p {
            font-size: 1em !important; }
      .createAssignmentForm__body .form .createAssignmentBtn {
        background: #732cbe;
        color: #fff; }

.assignmentView {
  position: relative;
  padding: 0;
  margin: 0;
  height: calc(100vh - 80px); }
  .assignmentView__backBtn {
    background: #fff !important;
    color: #252424 !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
    width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 10; }
    .assignmentView__backBtn:active {
      background: #eaeae9 !important; }
  .assignmentView__body {
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    height: 100%; }
    .assignmentView__body .col {
      height: 100%; }
    .assignmentView__body .contentContainer {
      padding: 1em;
      height: 100%;
      overflow: auto; }
      .assignmentView__body .contentContainer::-webkit-scrollbar {
        width: 6px; }
      .assignmentView__body .contentContainer::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      .assignmentView__body .contentContainer::-webkit-scrollbar-thumb {
        background-color: #8a00c15e;
        outline: 1px solid #b033ea; }
    .assignmentView__body .assignmentCard {
      margin: 1em 0;
      padding: 3em 1em;
      cursor: pointer; }
      .assignmentView__body .assignmentCard:active {
        background: #f0eeee; }
    .assignmentView__body #activeCard {
      border-bottom: 4px solid #732cbe; }
    .assignmentView__body .rightCard {
      height: 100%; }
      .assignmentView__body .rightCard .assignmentDetailCard {
        height: 100%;
        padding-bottom: 0; }
        .assignmentView__body .rightCard .assignmentDetailCard .top {
          height: 30%;
          overflow: auto;
          position: relative;
          font-size: 11px;
          padding-top: 0;
          margin: 0; }
          .assignmentView__body .rightCard .assignmentDetailCard .top::-webkit-scrollbar {
            width: 6px; }
          .assignmentView__body .rightCard .assignmentDetailCard .top::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
          .assignmentView__body .rightCard .assignmentDetailCard .top::-webkit-scrollbar-thumb {
            background-color: #8a00c15e;
            outline: 1px solid #b033ea; }
          .assignmentView__body .rightCard .assignmentDetailCard .top .topBtn {
            background: #fff;
            text-transform: capitalize; }
          .assignmentView__body .rightCard .assignmentDetailCard .top .joinBtn {
            background: #732cbe;
            color: #eee; }
          .assignmentView__body .rightCard .assignmentDetailCard .top .assignmentData__id {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            padding: 1px 1em;
            font-size: 9px; }
          .assignmentView__body .rightCard .assignmentDetailCard .top .assignmentData__dataContainer {
            width: 100%;
            max-width: 400px; }
          .assignmentView__body .rightCard .assignmentDetailCard .top h5 {
            margin: 0;
            font-size: 1.1em;
            padding-bottom: 8px; }
        .assignmentView__body .rightCard .assignmentDetailCard .bottom {
          height: 70%;
          overflow: auto;
          overflow-x: hidden; }
          .assignmentView__body .rightCard .assignmentDetailCard .bottom::-webkit-scrollbar {
            width: 6px; }
          .assignmentView__body .rightCard .assignmentDetailCard .bottom::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
          .assignmentView__body .rightCard .assignmentDetailCard .bottom::-webkit-scrollbar-thumb {
            background-color: #8a00c15e;
            outline: 1px solid #b033ea; }
          .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList {
            width: 100%;
            height: 100%; }
            .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table {
              position: relative; }
              .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .tableTop {
                padding: 0 1em 1em 1em;
                display: flex;
                justify-content: center;
                align-items: center;
                justify-content: space-between; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .tableTop h5 {
                  margin: 0;
                  font-size: 1.5em;
                  margin: 0.5em 0; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .tableTop button {
                  background: #fff;
                  margin: 0;
                  height: 20px;
                  text-transform: capitalize;
                  margin-right: 1em; }
              .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .col {
                text-align: left; }
              .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header {
                width: 100%;
                padding-bottom: 0.6em;
                border-bottom: 1px solid gray;
                background: #fff;
                font-size: 10px;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                left: 0;
                z-index: 10; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header .col {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  justify-content: flex-start; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header p {
                  font-weight: bold;
                  font-size: 1.2em; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header .totalCheck {
                  padding: 0;
                  margin: 0;
                  margin-left: 0.3em; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header .headerRow {
                  padding: 0 1em;
                  padding-top: 0;
                  padding-bottom: 0; }
                  .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header .headerRow .col {
                    padding-left: 0; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header .searchInput {
                  padding: 0 1em;
                  border-radius: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                  .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .header .searchInput input {
                    font-size: 0.8em;
                    border: none !important;
                    outline: none; }
              .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .body {
                width: 100%; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .body .row {
                  width: 100%;
                  padding: 0.5em 0;
                  border-bottom: 1px solid lightgray;
                  font-size: 0.5px;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .body .col {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  justify-content: flex-start;
                  align-items: center; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .body .name {
                  padding-left: 1em; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .body .avatar {
                  width: 30px;
                  height: 30px; }
                .assignmentView__body .rightCard .assignmentDetailCard .bottom .studentList .table .body .viewBtn {
                  text-transform: capitalize;
                  padding: 0; }

.demo button {
  background: purple;
  color: #fff;
  padding: 1em; }

.demo .canvas_container {
  display: flex;
  flex-wrap: wrap;
  background: rosybrown; }

.demo .canvas {
  margin: 1em; }

