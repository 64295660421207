.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    color: $scoar_purple;
    margin-bottom: 0;
  }

  &__subheader {
    color: gray;
    font-weight: normal;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    position: relative;
    width: 100%;

    div {
      border-radius: 25px;
      border-color: $scoar_purple;
      outline-color: $scoar_purple;
    }

    #input,
    .react-tel-input,
    .form-control {
      border-radius: 25px !important;
      width: 100%;
    }

    button {
      border-radius: 25px;
      margin: 1em 0;
      width: 100%;
      padding: 1.1em;
      font-size: 1em;
      outline: none;

      &:active {
        background: $scoar_purple_dark;
      }

      &:disabled {
        background: #dddddd;
        color: gray;
      }
    }

    .nextLoading{
      position: absolute;
      bottom: 32px;
    }


    .radiogroup {
      display: flex;
      flex-direction: row;
      padding: 0.3em;
      margin-bottom: 1em;
      border: 1.5px solid gray;
    }
  }
}

.authmodalcontainer {
  display: inline;
  background: transparent;
  &__modal {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;


    .container {
      outline: none;
      border: none;
      position: relative;
      background: #fff;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);
      height: 80vh;
      padding: 0;
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%,-50%);

      .backBtn{
        position: absolute;
        padding: 1em;
        top: 1em;
        cursor: pointer;
      }

      .welcome {
        padding: 2.5em;
        margin: 0;
        width: 100%;
        height: 80vh;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22);
        position: relative;

        #logo {
          position: absolute;
          top: 2.5em;
					left: 2.5em;
					padding: 0;
					height: 60px;

					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					img{
						width: 200px;
          	height: auto;
					}

					h1{
						padding: 0;
						margin: 0;
						display: inline-block;
					}
        }

        #coaching {
          width: 50%;
          height: auto;
          position: absolute;
          top: 2em;
          right: 2em;
        }

        #teacher {
          width: 40%;
          height: auto;
          position: absolute;
          bottom: 2em;
          right: 3em;
				}
				
				h1{
          position: relative;
					margin-top: 36%;
          font-size: 2.5em;
          z-index: 999;
				}

				h2{
					margin-bottom: 2em;
        }
        
        .signup-link-text{
          position: absolute;
          bottom: 30px;
        }

      }

      .form {
        padding: 2em;
        margin: 0;
        width: 100%;
        height: 100%;
        width: 100%;
				height: 80vh;
				display: flex;
				align-items: center;
				justify-content: center;
      }

      .cancel-btn {
        position: absolute;
        right: 1em;
        top: 1em;
        cursor: pointer;
      }
    }
  }
}
