@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.purple {
  color: $scoar-purple;
}

.pink {
  color: rgb(234, 27, 172);
}

.rotate180 {
  transform: rotate(180deg);
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  // background: $scoar-purple;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 9999;

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: rgb(36, 35, 35);
    margin-left: 2em;
    img {
      width: 50px;
      height: auto;
      margin: 0 1em;
    }
  }

  &__links {
    margin-right: 2em;
    a {
      padding: 0.4rem 1.8rem;
      font-size: 1.2em;
      text-decoration: none;
      color: #fff;
      transition: $transition;
      text-shadow: -5px 3px 8px rgba(150, 150, 150, 1);

      span {
        background: transparent;
      }

      &:last-child {
        background: #fff;
        color: $scoar-purple;
        padding: 0.2rem 1rem;
        border-radius: 28px;
        border: 2px solid #fff;
        box-shadow: $shadow;
        text-shadow: none;
        &:hover {
          background:  $scoar-purple;
          color: #fff;
          border-color: $scoar-purple;
          box-shadow: 0 3px 6px rgba(63, 5, 70, 0.16), 0 3px 6px rgba(64, 4, 97, 0.23);
        }
      }
    }
  }
}

.landing {
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  color: rgb(34, 19, 39);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative;

  &::before {
    content: "";
    z-index: -1;
    background: rgb(199, 51, 51);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(247, 246, 246, 0.7896273743872549) 0%,
      rgba(212, 210, 210, 1) 100%
    );
    background-image: url("/icons/landing/upper_bg.svg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 130%;
    position: absolute;
    left: 0;
    top: -10%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__top {
    .left {
      padding: 2.5em;
      font-size: 1.5em;
      display: flex;
      flex-direction: column;
      line-height: 1.3;
      align-items: flex-start;
      h2 {
        font-weight: 300;
      }

      .input {
        background: rgb(255, 255, 255);
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        box-shadow: $shadow;
        height: 48px;
        width: 100%;
        max-width: 450px;
        input {
          border: none;
          border-radius: 25px;
          outline: none;
          padding: 1.1em 1em;
          font-size: 1rem;
          background: transparent;
          width: 80%;
          height: 100%;
        }

        img {
          height: 48px;
          width: auto;
          cursor: pointer;
        }
      }
    }

    .right{
      width: 100%;
      height: 100%;
    }
  }

  &__top2 {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 3em;
    .innerBox {
      @include purple-pink-gradient;
      border-radius: 15px;
      width: 100%;
      box-shadow: $shadow-spread;
      padding: 1em;
      height: 130px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff;
    }
  }

  &__section {
    width: 90%;
    max-width: 1200px;
    margin: 1em auto;

    h2 {
      font-size: 2.5em;
      padding: 1em;
      font-weight: 400;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        padding: 2em;
        line-height: 1.6;
      }
    }
    .left {
      text-align: left;
      align-items: flex-start;
    }

    .right {
      text-align: right;
      align-items: flex-end;
    }
  }

  &__subscribeSection {
    width: 90%;
    max-width: 1200px;
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center;

    h2,
    h3 {
      text-align: center;
      color: #2f2d2d;
    }

    h3 {
      font-weight: normal;
    }

    .emailbox {
      background-color: #d8d8d8;
      border-radius: 8px;
      width: 100%;
      max-width: 765px;
      height: 68px;
      overflow: hidden;
      margin: 2em auto;
      input {
        width: 80%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        padding: 6px 1em;
      }
      button {
        width: 20%;
        height: 100%;
        background-color: #130242;
        border-radius: 8px;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .testimonialbox {
      background-image: linear-gradient(109.54deg, #8468f5 0%, #ec74e7 100%);
      border-radius: 12px;
      box-shadow: inset 0 36px 43px rgba(0, 0, 0, 0.2);
      width: 845px;
      height: 173px;
      overflow: hidden;
      margin: 2em auto;
      position: relative;
      padding: 1em 3em;
      .controlDots{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 30px;
        position: absolute;
        right: 20px;
        top: 0;
        span{
          width: 13px;
          height: 13px;
          margin: 4px;
          border-radius: 50%;
          background: transparent;
          border: 2px solid #fff;
          cursor: pointer;
        }

        .activeDot{
          background: #fff;
        }
      }

      .upper{
        margin-top: 1em;
        padding-left: 2em;
        @include flex-center;
        flex-wrap: wrap;
        justify-content: flex-start;

        p{
          margin: 0 1em;
          color: #fff;
          p{
            font-size: 12px;
            position: relative;
            left: -10px;
            font-weight: normal;
            padding-top: 2px ;
          }
        }
      }

      .lower{
        font-size: 0.9em;
        padding: 0 1em;

        p{
          padding: 1em;
          color: #fff;
        }
      }


    }
  }

  &__footer{
    width: 100%;
    max-width: 1200px;
    border-top: 2px solid #544837;
    padding: 2em 1em;

    .logo,
    .social,
    .links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        a{
          text-decoration: none;
          font-size: 1.3em;
          margin: 0.3rem 0;
          color: #544837;
        }
      }

      .logo{
        img{
          width: 180px;
          height: auto;
          padding: 1em; 
        }
      }

      .social{
        svg {
        position: relative;
        bottom: -5px;
        }
        .linkedin{
          color: #0077b7;
        }

        .facebook{
          color: #2196f3;
        }

        .twitter{
          color: #03a9f4;
        }
      }

      .footerImage{
        width: 100%;
        height: fit-content;
        text-align: center;
        margin: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
  }
}

.cardCarousel{
  width: 60%;
  
  height: 340px;
  // background: yellow;
  position: absolute;
  right: -100px;
  display: flex;
  @include flex-center;
  overflow: hidden;
  .card{
    height: 90%;
    width: 100px;
    background: #fff;
    margin: 1em;
    border-radius: 20px;
    box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.38);
  }

  .b2{
    height: 60%;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-55%);
    opacity: 0.7;
  }

  .b1{
    height: 75%;
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-55%);
  }

  .f1,
  .f2,
  .f3{
    padding: 2em;
    position: relative;
    left: 100px;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.38), inset 0px -5px 8px 9px rgba(255,255,255,1);

    .avatarContainer{
      width: 100%;
      @include flex-center;
      justify-content: flex-start;
      p{
        padding-left: 8px;
      }
      height: 30%;
      // background: yellowgreen;
    }

    .textContainer{
      font-weight: normal;
      font-size: 13px;
      line-height: 1.5;
      overflow: hidden;
      color: rgb(94, 80, 105);
    }
  }

  
}

@media only screen and (max-width: 1024px) {
  .cardCarousel{
    display: none;
  }
}