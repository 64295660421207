// ####### PAYMENT COMPONENT
.payment {
	margin-top: -1em;
	position: relative;
	z-index: 888;

	&__dataHeader {
		padding: 1em 0;
		.blueBox {
			padding: 1em;
			height: 110px;
			background: linear-gradient(115deg, white 0%, #a283ff 0%, #341c86 100%);
			border-radius: 8px;
			box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.2);
			.heading {
				font-size: 1.5em;
				font-weight: bold;
				color: #fff;
				@include flex-center;
				justify-content: start;

				button {
					text-transform: capitalize;
					background: #fff;
				}
			}

			.body {
				font-size: 2.5em;
				font-weight: bold;
				color: #fff;

				button {
					text-transform: capitalize;
					background: #fff;
				}
			}
		}
	}

	&__dataContainer {
		.dataRow {
			height: 300px;
			margin: 1em 0;

			.leftMoney {
				position: relative;
				// background: pink;
				padding: 1em;
				height: 100%;
				&__left {
					.dataContainer {
						display: flex;
						margin-top: 2em;
						.icon {
							width: 20%;
							height: 45px;
							border-left: 4px solid $blue;
							@include flex-center;
							span {
								background: $blue;
								width: 40px;
								height: 40px;
								color: #fff;
								padding: 1em;
								@include flex-center;
								border-radius: 8px;
							}
						}
						.studentDetails {
							width: 80%;
							h4 {
								margin: 0;
								font-size: 1.3em;
							}
							p {
								@include flex-center;
								justify-content: flex-start;
								svg {
									width: 0.7em;
									height: 0.7em;
								}
							}
						}
					}
					.btnContainer {
						position: absolute;
						bottom: 3em;
						left: 1em;
						width: 100%;

						button {
							background: #fff;
							text-transform: capitalize;
							border: 1px solid rgba(105, 37, 151, 0.516);
						}
					}
				}

				&__right {
					position: absolute;
					right: 0;
					top: 0;
					width: 130px;
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-evenly;

					button {
						border: 2px solid rgba(250, 148, 101, 0.938);
						border-radius: 8px;
						width: 100%;
						display: flex;
						justify-content: space-between;
						box-shadow: $shadow;
					}

					.activeBtn {
						border: 2px solid $blue;
					}
				}
			}

			.paidPeopleList {
				.header {
					height: 40px;
					font-weight: bold;
					padding: 0.5em;
					padding-top: 0.8em;
					white-space: nowrap;
					.col {
						@include flex-center;
					}
				}
				.body {
					height: 260px;
					width: 100%;
					overflow-y: auto;
					@include purple-scrollbar;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}
// ####### PAYMENT COMPONENT END

// ####### HISTORY MODAL
.historyModal {
	#customized-dialog-title {
		.balance {
			display: flex;
			justify-content: space-between;
			div {
				margin-left: 20%;
			}

			h2 {
				font-weight: bold;
				font-size: 2.5em;
				margin: 0;
			}
		}
	}

	.table {
		padding: 0 1em;
		.col {
			text-align: left;
		}
		.header {
			width: 100%;
			padding-bottom: 0.8em;
			border-bottom: 1px solid gray;
			.col {
				@include flex-center;
				justify-content: flex-start;
			}
			p {
				font-weight: bold;
				font-size: 1.2em;
			}
		}

		.body {
			width: 100%;

			.row {
				width: 100%;
				padding: 1em 0;
				border-bottom: 1px solid lightgray;
			}
			.col {
				@include flex-center;
				justify-content: flex-start;
			}
			.name {
				padding-left: 1em;
			}
		}
	}
}
// ####### HISTORY MODAL END

// ######## MONEYINFO COMPONENT
.MoneyInfo {
	display: flex;
	justify-content: space-between;
	border-radius: 8px;
	width: 100%;
	min-width: 100px;
	height: 100%;
	margin: 0;

	.left {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		align-items: flex-start;
		.head {
			font-size: 1em;
			font-weight: bold;
		}

		.subhead {
			font-size: 0.7em;
			font-weight: bold;
			text-transform: capitalize;
			color: rgb(65, 65, 65);
		}
	}

	.right {
		padding: 0em;
		@include flex-center;
		border-radius: 50%;
		margin-left: 0.5em;
		background: rgb(236, 255, 255);
		.MuiBox-root {
			width: 100%;
			height: 100%;

			.MuiTypography-root {
				font-size: 9px;
				font-weight: bold;
				@include flex-center;
			}
		}
	}
}
// ######## MONEYINFO COMPONENT END

// ######## PaidPeopleListItem COMPONENT
.paidPeopleListItem {
	width: 98%;
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.07);
	padding: 0.5em;
	border-radius: 8px;
	margin: 0.5em auto;
	font-weight: bold;
	&__time {
		@include flex-center;
		font-size: 0.8em;
		color: gray;

		.dateData {
			padding: 0;
			padding-right: 10px;
		}
	}

	&__name {
		@include flex-center;
		justify-content: flex-start;
		.content {
			@include flex-center;
			flex-direction: column;
			align-items: flex-start;
			overflow: hidden;
			font-size: 0.8em;
			color: gray;
			padding: 0 0.5em;
			padding-left: 1em;
			.orange {
				color: rgb(255, 81, 0);
			}
		}
	}

	&__mode {
		@include flex-center;
		// flex-direction: column;
		// align-items: flex-start;
		// overflow: hidden;
		font-size: 0.8em;
		color: gray;
		// padding: 0 0.5em;
		// padding-left: 1em;
		// .orange {
		// 	color: rgb(255, 81, 0);
		// }
	}

	&__btn {
		@include flex-center;

		button {
			font-size: 0.8em;
			text-transform: capitalize;
			padding: 2px 4px;
		}
	}
}
// ######## PaidPeopleListItem COMPONENT

// ######## studentPaymentLeftCard start
.studentPaymentLeftCard {
	padding: 1em;
	border-radius: 15px !important;
	position: relative;
	height: 230px !important;
	.dueIcon {
		position: absolute;
		top: 2em;
		right: 2em;
		cursor: pointer;
	}
}
// ######## studentPaymentLeftCard end
